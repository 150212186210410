import Repository from './repository'

export default {
  getStaffs() {
    return Repository.get('/v1/admin/staffs')
  },
  deleteStaff({ staffId }) {
    return Repository.delete(`/v1/admin/staffs/${staffId}`)
  },
  createStaff({ staff }) {
    return Repository.post('/v1/admin/staffs', { staff })
  },
  updateStaff({ staff }) {
    return Repository.patch(`/v1/admin/staffs/${staff.id}`, { staff })
  },
  updateStaffProfile({ staffId, profile }) {
    return Repository.patch(`/v1/admin/staffs/${staffId}/profile`, { profile })
  },
}
