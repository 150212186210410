<template>
  <base-label :class="labelClass" :for="name">
    {{ label }}
    <div class="select-div">
      <select
        :name="name"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-bind="$attrs"
        class="block p-3 w-full h-12 bg-white border rounded"
        :class="{ 'mt-2': label }"
      >
        <option v-if="placeholder" :disabled="!allowEmpty" value>
          {{ placeholder }}
        </option>
        <option
          v-for="(value, key) in optionsObject"
          :value="key"
          :key="key"
          :selected="modelValue == key"
        >
          {{ value }}
        </option>
      </select>
    </div>
  </base-label>
</template>

<script>
import humanize from '@/utils/humanize'

export default {
  inheritAttrs: false,

  props: {
    modelValue: [Number, String],
    allowEmpty: { type: Boolean, default: true },
    placeholder: {
      type: [Boolean, String],
      default: 'Select',
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: [String, Array],
      required: false,
    },
    options: [Array, Object],
    // Pass options either as an array of string
    // ['my_option', ... ] => will be rendered as 'My option'
    // or as an object { 'key': 'Option name', ... }
  },

  computed: {
    name() {
      return this.label?.toLowerCase()
    },
    optionsObject() {
      // If options is not an array, just return it
      if (!Array.isArray(this.options)) return this.options

      // Otherwise, convert it to an Object using index as keys
      return Object.fromEntries(this.options.map(el => [el, humanize(el)]))
    },
  },

  methods: {
    humanize,
  },
}
</script>

<style lang="postcss">
.select-div {
  @apply relative;
}
.select-div:after {
  @apply content-['∨'] absolute right-3 top-1/2 pb-1 text-gray-400 font-bold pointer-events-none -translate-x-1/2 -translate-y-1/2 scale-x-150 scale-y-90;
}
select {
  @apply appearance-none;
}
</style>
