export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async connect({ rootGetters }, { channel = null, ...data } = {}) {
    if (rootGetters['auth/loggedIn']) {
      await this.$cable.connection.connect(rootGetters['auth/wsConnectionUrl'])
      if (channel) await this.dispatch('cable/subscribe', { channel, ...data })
    }
  },

  async subscribe(_, { channel, ...data }) {
    this.$cable.subscribe({ channel, ...data })
  },

  async unsubscribe(_, channel) {
    await this.$cable.unsubscribe(channel)
  },

  async disconnect() {
    await this.$cable.connection.disconnect()
  },
}
