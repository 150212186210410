import GuestsRepository from '@/api/repositories/guests.js'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchGuests({ dispatch }, params = {}) {
    const { previousRequest, cancelToken } = await dispatch(
      'getCancelTokens',
      'guests/fetchGuests',
      { root: true }
    )
    previousRequest?.cancel('Request canceled by new request')

    const res = await GuestsRepository.getGuests({ ...params, cancelToken })

    // Cache bookings by scope
    const { scope } = params
    dispatch(
      'tables/cacheData',
      { module: 'guests', scope, data: res.data },
      { root: true }
    )

    if (res.status === 200) return res.data
  },

  async fetchGuest(_, { id = null, email = null } = {}) {
    const res = await GuestsRepository.getGuest({ id, email })
    if (res.status === 200) return res.data
  },

  async upsertGuest(_, { guest }) {
    let res
    if (guest.id) {
      res = await GuestsRepository.patchGuest({ guest })
    } else {
      res = await GuestsRepository.createGuest({ guest })
    }
    if (res.status === 200) return res.data
  },
}
