import Repository from './repository'

export default {
  newCheckin(bookingNo, params = {}) {
    return Repository.get(`/v1/bookings/${bookingNo}/checkins/new`, {
      params,
    })
  },
  postCheckin({ bookingNo, checkin, guest }) {
    return Repository.post(`/v1/admin/bookings/${bookingNo}/checkins`, {
      checkin,
      guest,
    })
  },
  // From Booking page, update checkin and guest information
  patchCheckin({ checkin }) {
    return Repository.patch(`/v1/admin/checkins/${checkin.id}`, { checkin })
  },
  deleteCheckin({ checkin }) {
    return Repository.delete(`/v1/admin/checkins/${checkin.id}`)
  },
  approveCheckin({ checkin }) {
    return Repository.post(`/v1/admin/checkins/${checkin.id}/approve`)
  },
  declineCheckin({ checkin, declineReason }) {
    return Repository.post(`/v1/admin/checkins/${checkin.id}/decline`, {
      declineReason,
    })
  },
  moveCheckin({ checkin, newBookingNo, overridePrimary }) {
    return Repository.post(`/v1/admin/checkins/${checkin.id}/move`, {
      newBookingNo,
      overridePrimary,
    })
  },
}
