<template>
  <modal
    class="shadow-lg"
    :class="alert?.classes"
    v-bind="$attrs"
    ref="modal"
    @close="closeAlert"
    :z-index="100"
  >
    <p
      v-if="title"
      :class="[
        'text-lg sm:text-2xl lg:text-3xl text-left font-medium',
        { 'text-center': singleMessage },
      ]"
      v-html="title"
    ></p>
    <ul
      v-if="messages.length > 0"
      class="flex flex-col gap-y-3 w-full sm:gap-y-5"
    >
      <li
        v-for="(message, index) in messages"
        :key="index"
        class="m-0 w-full break-words text-base sm:gap-x-6 sm:text-lg md:gap-x-8 lg:text-xl"
        :class="{ 'text-center': singleMessage }"
        v-html="message"
      />
    </ul>
    <div class="relative w-full" v-if="trace">
      <pre
        class="border rounded whitespace-pre-wrap w-full border-primary p-2 resize-none"
        disabled
        rows="4"
        >{{ trace }}</pre
      >
      <icon-copy
        @click="copyTrace"
        class="absolute top-2 right-2 text-gray-500 hover:text-black transition-all ease-in-out cursor-pointer"
      ></icon-copy>
    </div>
    <div class="flex items-center justify-around w-full font-medium">
      <base-button
        v-if="cancelButtonText"
        @click="closeAlert"
        class="min-w-[7rem]"
        transparent
      >
        {{ cancelButtonText }}
      </base-button>
      <base-button
        v-if="buttonText"
        @click="onButtonClick"
        class="min-w-[7rem]"
        black
      >
        {{ buttonText }}
      </base-button>
    </div>
    <slot />
  </modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import useKeyboardInput from '@/use/use-keyboard-input'
import { Comment, computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['close'])

const store = useStore()

const alert = computed(() => store.getters['alert'])

const modal = ref<typeof Modal | null>(null)

watchEffect(() => {
  if (!modal.value) return

  if (alert.value && modal.value) modal.value.show()
  else modal.value.close()
})

const singleMessage = computed(() => {
  return !alert.value?.messages || alert.value?.messages.length === 1
})

const title = computed(() => {
  return alert.value?.title
})

const messages = computed(() => {
  return alert.value?.messages || []
})

const buttonText = computed(() => {
  return alert.value?.buttonText === undefined
    ? 'Close'
    : alert.value?.buttonText
})

const cancelButtonText = computed(() => {
  return alert.value?.cancelButtonText
})

const hasSlot = computed(() => {
  return (
    slots.default && slots.default().findIndex(o => o.type !== Comment) !== -1
  )
})

const trace = computed(() => {
  if (!alert.value?.error) return

  const { exception, traces } = alert.value.error
  const location = traces['Application Trace']?.[0]?.['trace']
  const message = `Location: ${location}\nException: ${exception}\nTimestamp: ${new Date().toISOString()}`

  return message
})

const closeAlert = () => {
  emit('close')
  store.dispatch('resetAlert')
}

const onButtonClick = () => {
  if (alert.value.buttonAction) alert.value.buttonAction()
  closeAlert()
}

const keyboardInput = e => {
  if (e.key === 'Escape') {
    closeAlert()
  }
}

const copyTrace = async event => {
  try {
    await navigator.clipboard.writeText(trace.value || '')
  } catch (error) {
    console.error(error.message)
  }
}

useKeyboardInput(keyboardInput)
</script>
