<template>
  <div class="relative">
    <slot>
      <icon-three-dots
        v-if="actions"
        class="cursor-pointer"
        @click.stop="toggle"
        :class="{ 'rotate-90': dotsHorizontal }"
      />
    </slot>
    <div
      v-if="actions?.length && visible"
      class="
        dropdown
        absolute
        grid
        gap-2
        p-6
        w-min
        whitespace-nowrap
        bg-white
        rounded
      "
      :class="{
        'top-8 -right-9': !offset,
        'top-16 -right-6': offset,
      }"
      v-click-outside="onClickOutside"
    >
      <div v-for="(action, index) in actions" :key="index" @click="close">
        <base-link v-if="action.to !== undefined" :to="action.to">
          <p class="cursor-pointer" :class="action.class">
            {{ action.label }}
          </p>
        </base-link>
        <p
          v-else-if="action.function !== undefined"
          class="cursor-pointer"
          :class="action.class"
          @click.stop="action.function()"
        >
          {{ action.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import IconThreeDots from '@/components/icons/IconThreeDots.vue'
import { ref } from 'vue'

export default {
  expose: ['toggle'],

  components: {
    IconThreeDots,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    actions: Array,
    dotsHorizontal: { type: Boolean, default: false },
    offset: Boolean,
  },

  setup() {
    const visible = ref(false)
    const onClickOutside = () => {
      visible.value = false
    }

    const toggle = () => {
      visible.value = !visible.value
    }

    const show = () => {
      visible.value = true
    }

    const close = () => {
      visible.value = false
    }

    return { show, close, visible, onClickOutside, toggle }
  },
}
</script>

<style lang="postcss">
.dropdown {
  box-shadow: 0rem -0.1rem 2rem rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.dropdown::after {
  @apply absolute right-8 w-4 h-4 bg-white transform -translate-x-1/2 -translate-y-2 rotate-45;
  content: '';
}
</style>
