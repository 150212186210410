import Repository from './repository'
import snakeCase from 'lodash/snakeCase'
import type Event from '@/types/api/types/Admin/Event'

export default {
  getEvent(eventId) {
    return Repository.get<Event>(`/v2/admin/events/${eventId}`)
  },
  deleteComment(commentId) {
    return Repository.delete(`/v1/admin/comments/${commentId}`)
  },
  getEvents({ scope, eventType, search }) {
    return Repository.get<{ events: Event[] }>(`/v2/admin/events`, {
      params: { scope, eventType: snakeCase(eventType), search },
    })
  },
  createEvent({ event }) {
    return Repository.post<Event>(`/v2/admin/events`, { event })
  },
  deleteEvent({ eventId }) {
    return Repository.delete(`/v1/admin/events/${eventId}`)
  },
  duplicateEvent({ eventId }) {
    return Repository.post(`/v2/admin/events/${eventId}/duplicate`)
  },
  updateEvent({ eventId, event }) {
    return Repository.patch<Event>(`/v2/admin/events/${eventId}`, { event })
  },
}
