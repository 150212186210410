import Repository from './repository'

export default {
  getChats({ search = null, propertyId = null } = {}) {
    return Repository.get(`/v1/admin/chats`, {
      params: { search, propertyId },
    })
  },
  getChat({ chatId }) {
    return Repository.get(`/v2/admin/chats/${chatId}`)
  },
}
