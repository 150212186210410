<template>
  <div v-if="visible" class="contents">
    <div
      class="
        hide-scrollbars
        fixed
        left-1/2
        top-1/2
        flex flex-col
        gap-y-3
        items-center
        justify-between
        px-6
        py-8
        w-11/12
        bg-white
        rounded-lg
        transform
        -translate-x-1/2 -translate-y-1/2
        sm:gap-y-8 sm:p-12 sm:max-w-2xl
        lg:max-w-2xl
      "
      :style="`z-index: ${zIndex};`"
      v-bind="$attrs"
    >
      <div
        @click="close"
        class="
          absolute
          right-3
          top-3
          text-gray-500 text-xl
          leading-none
          cursor-pointer
        "
      >
        ✕
      </div>
      <slot />
    </div>
    <overlay
      @click="close"
      class="cursor-pointer"
      :style="`z-index: ${zIndex - 1};`"
    />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import Overlay from '@/components/Overlay.vue'
import { ref } from 'vue'

withDefaults(
  defineProps<{
    zIndex?: number
  }>(),
  { zIndex: 50 }
)

const emit = defineEmits<{
  (e: 'show'): void
  (e: 'close'): void
}>()

const visible = ref(false)

const close = () => {
  visible.value = false
  emit('close')
}

const show = () => {
  visible.value = true
  emit('show')
}
defineExpose({ show, close })
</script>

<style>
.hide-scrollbars::-webkit-scrollbar {
  width: 0 !important;
}
</style>
