import Repository from './repository'

export default {
  getProperties() {
    return Repository.get('/v1/admin/properties')
  },
  getPropertiesForMinpaku() {
    return Repository.get('/v1/admin/properties', {
      params: { includeMinpakuReport: 'true' },
    })
  },
  getProperty({ propertyId }) {
    return Repository.get(`/v1/admin/properties/${propertyId}`)
  },
  postPropertyRoomUnits({ propertyId, roomUnits }) {
    return Repository.post(
      `/v1/admin/properties/${propertyId}/room_unit_bulk_updates`,
      {
        roomUnitsAttributes: roomUnits,
      }
    )
  },
  patchPropertyRoomUnit({ propertyId, roomUnitId, minpakuNumber }) {
    return Repository.patch(
      `/v1/admin/properties/${propertyId}/room_units/${roomUnitId}`,
      {
        minpakuNumber,
      }
    )
  },
  downloadPropertyRoomUnitMinpakuReport({
    propertyId,
    roomUnitId,
    periodOfReporting,
  }) {
    return Repository.get(
      `/v1/admin/properties/${propertyId}/room_units/${roomUnitId}/minpaku_report/download`,
      {
        periodOfReporting,
      }
    )
  },
  getPropertyMinpakuReports({ propertyId, periodOfReporting }) {
    return Repository.get(
      `/v1/admin/properties/${propertyId}/minpaku_reports`,
      {
        periodOfReporting,
      }
    )
  },
  postPropertyMinpakuReports({ propertyId }) {
    return Repository.post(`/v1/admin/properties/${propertyId}/minpaku_reports`)
  },
}
