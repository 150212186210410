import { config } from '@/constants'
import compact from 'lodash/compact'

export default function cloudinaryImgUrl(
  imageKey,
  {
    options = '',
    ext = 'jpg',
    cloudName = config.cloudinary.cloudName,
    timestamp = Math.floor(Date.now() / 1000),
  } = {}
) {
  if (!imageKey) return

  const filepath = compact([
    options,
    `v${timestamp}`,
    `${imageKey}.${ext}`,
  ]).join('/')

  return `https://res.cloudinary.com/${cloudName}/image/upload/${filepath}`
}
