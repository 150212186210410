<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="27"
      rx="3.5"
      fill="white"
      stroke="currentColor"
    />
  </svg>
</template>
