<template>
  <base-label class="flex items-center">
    <input
      type="checkbox"
      v-model="model"
      class="hidden"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <icon-checkedbox v-show="model" />
    <icon-uncheckedbox v-show="!model" />
    <div class="ml-2">
      <slot />
    </div>
  </base-label>
</template>

<script>
import IconCheckedbox from '@/components/icons/IconCheckedbox.vue'
import IconUncheckedbox from '@/components/icons/IconUncheckedbox.vue'
import { ref } from 'vue'

export default {
  components: { IconCheckedbox, IconUncheckedbox },

  setup() {
    const model = ref(false)

    return { model }
  },
}
</script>
