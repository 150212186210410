import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { config } from './constants'

export default function sentry(app, router) {
  Sentry.init({
    app,
    environment: config.environment,
    dsn: config.sentryDsn,
    release: config.appVersion,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /https?:\/\/.+\.section-l\.co/, /^\//],
      }),
    ],
    tracesSampleRate: config.environment === 'production' ? 0.2 : 1,
    debug: config.environment !== 'production',
    tracingOptions: {
      trackComponents: true,
    },
    // Vue specific
    logErrors: config.environment === 'production' ? false : true,
    attachProps: true,
    attachStacktrace: true,
  })
}
