<template>
  <div class="flex gap-2" v-if="buttonMode">
    <button
      v-for="option in options"
      @click="$emit('update:modelValue', option.value)"
      class="flex items-center justify-center bg-gray-100 w-10 h-10 rounded-full"
      :class="{
        'bg-primary text-white': option.value === modelValue,
      }"
    >
      {{ option.label }}
    </button>
  </div>
  <div class="grid gap-2" v-else>
    <label
      v-for="option in options"
      :key="option.value"
      class="flex gap-3 items-center"
    >
      <input
        type="radio"
        @input="$emit('update:modelValue', option.value)"
        :checked="modelValue === option.value"
        class="hidden"
      />
      <div
        class="min-w-6 grid place-items-center w-6 h-6 border border-primary rounded-full"
      >
        <div
          :class="{
            'w-4 h-4 bg-primary rounded-full': option.value === modelValue,
          }"
        />
      </div>
      {{ option.label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    modelValue: [Boolean, String, Number],
    buttonMode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
