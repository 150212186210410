<template>
  <div
    v-if="url && loading"
    class="relative loading-shimmer"
    v-bind="$attrs"
  />
  <img
    v-show="!loading"
    :src="url"
    @load="loading = false"
    @error="loading = false"
    v-bind="$attrs"
    class="object-cover text-xs font-normal"
  />
</template>

<script>
import cloudinaryImgURL from '@/utils/cloudinary-img-url'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  inheritAttrs: false,

  props: {
    imageKey: String,
    params: { type: Object, default: () => {} },
  },

  setup(props) {
    const store = useStore()

    // Set a timestamp in the store to use cached images
    const timestamp = store.getters['cloudinaryTimestamp']

    const url = computed(() =>
      cloudinaryImgURL(props.imageKey, { timestamp, ...props.params })
    )

    const loading = ref(true)

    return { url, loading }
  },
}
</script>

<style lang="postcss">
.loading-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
