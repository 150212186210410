const apiURL =
  import.meta.env[import.meta.env.VITE_API_OVERRIDE] ||
  import.meta.env.VITE_API_DEV_URL ||
  `http://${window.location.hostname}:3000`

export const config = {
  algolia: {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    key: import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_KEY,
    bookingsIndex: import.meta.env.VITE_ALGOLIA_INDEX_NAME_BOOKINGS, // Checkin date ascending (next earliest checkin first) (DEFAULT: Today, Tomorrow, Future)
    bookingsIndexCheckinDesc: import.meta.env
      .VITE_ALGOLIA_INDEX_NAME_BOOKINGS_CHECKIN_DESC, // Checkin date descending (latest checkin first) (In-stay)
    bookingsIndexCheckoutAsc: import.meta.env
      .VITE_ALGOLIA_INDEX_NAME_BOOKINGS_CHECKOUT_ASC, // Checkout date ascending (upcoming checkout first) (In-stay)
    bookingsIndexCheckoutDesc: import.meta.env
      .VITE_ALGOLIA_INDEX_NAME_BOOKINGS_CHECKOUT_DESC, // Checkout date descending (latest checkout first) (Past)
    bookingsIndexRoomNumberDesc: import.meta.env
      .VITE_ALGOLIA_INDEX_NAME_BOOKINGS_ROOM_NUMBER_DESC, // Room number descending
  },
  appVersion: import.meta.env.PACKAGE_VERSION,
  environment: import.meta.env.MODE || 'development',
  apiURL,
  apiHost: new URL(apiURL).host,
  apiServers: {
    staging: import.meta.env.VITE_API_STAGING_URL,
    development: import.meta.env.VITE_API_DEV_URL,
  },
  cloudinary: {
    cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  google: {
    apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
    mapURL: `https://www.google.com/maps/embed/v1/place?key=${
      import.meta.env.VITE_GOOGLE_MAP_API_KEY
    }`,
  },
  faro: {
    appKey: import.meta.env.VITE_FARO_APP_KEY,
  },
}
