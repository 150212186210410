import { createApp } from 'vue'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import App from './App.vue'
import router from './router'
import store from './store'
import sentry from './sentry'
import { config } from './constants'

const app = createApp(App)

// Faro (Grafana's Frontend Observability Solution)
initializeFaro({
  url: `https://faro-collector-prod-ap-southeast-0.grafana.net/collect/${config.faro.appKey}`,
  app: {
    name: 'Admin',
    version: config.appVersion,
    environment: config.environment,
  },
  instrumentations: [...getWebInstrumentations()],
})

// Sentry
if (config.environment === 'production') sentry(app, router)

// Globally register all `_base`-prefixed components
import globals from '@/components/globals'
globals(app)

// ActionCable
import ActionCableVue from 'actioncable-vue'

const actionCableVueOptions = {
  debug: config.environment === 'development',
  debugLevel: 'error',
  connectImmediately: false,
  store,
}

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
app.component('font-awesome-icon', FontAwesomeIcon)

app
  .use(store)
  .use(router)
  .use(ActionCableVue, actionCableVueOptions)
  .mount('#app')
