import Repository from './repository'

export default {
  getStaff(staffId, params = {}) {
    return Repository.get(`/v1/staffs/${staffId}`, { params })
  },
  getGuest(guestId, params = {}) {
    return Repository.get(`/v1/guests/${guestId}`, { params })
  },
}
