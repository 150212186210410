<template>
  <ul class="flex justify-start">
    <NavBarRoutes v-if="loggedIn" :routes="loggedInNavRoutes" />
    <NavBarRoutes v-else :routes="loggedOutNavRoutes" />
    <UserSettingsDropdown v-if="loggedIn" />
  </ul>
</template>

<script>
import { authComputed } from '@/store/helpers'
import NavBarRoutes from '@/components/NavBarRoutes.vue'
import UserSettingsDropdown from '@/components/nav/UserSettingsDropdown.vue'

export default {
  components: { NavBarRoutes, UserSettingsDropdown },

  data() {
    return {
      loggedInNavRoutes: [
        {
          name: 'Bookings',
          title: 'Bookings',
        },
        {
          name: 'Guestbook',
          title: 'Guestbook',
        },
        {
          name: 'Messages',
          title: 'Messages',
        },
        {
          name: 'Events',
          title: 'Events',
        },
        {
          name: 'Members',
          title: 'Members',
        },
        {
          name: 'Reports',
          title: 'Reports',
        },
      ],
      loggedOutNavRoutes: [
        {
          name: 'Login',
          title: 'Log in',
          icon: {
            name: 'sign-in-alt',
          },
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>
