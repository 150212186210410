<template>
  <button
    @click="goTo"
    class="flex items-center justify-center px-8 py-4 text-base font-medium border-2 border-transparent rounded-lg transform duration-200"
    :class="{
      primary,
      black,
      secondary,
      flat,
      white,
      round,
      fullyRounded,
      outlined,
      transparent,
      red,
      green,
      gray,
      skinny,
    }"
    :disabled="$attrs.disabled || appLoading"
    v-bind="omit($attrs, 'disabled')"
  >
    <base-icon
      v-if="icon"
      :name="icon.name"
      :class="[icon.class, { 'mr-2': !round }]"
      :component="icon.component"
    />
    <slot />
  </button>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import omit from 'lodash/omit'

export default {
  inheritAttrs: false,

  props: {
    to: {
      type: [Object, Function],
      required: false,
    },
    icon: {
      type: Object,
      required: false,
    },
    // Variants
    primary: Boolean,
    white: Boolean,
    secondary: Boolean,
    flat: Boolean,
    round: Boolean,
    fullyRounded: Boolean,
    outlined: Boolean,
    transparent: Boolean,
    green: Boolean,
    gray: Boolean,
    red: Boolean,
    black: Boolean,
    skinny: Boolean,
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const appLoading = computed(() => store.getters.appLoading)

    const goTo = () => {
      if (props.to === undefined) return

      if (props.to?.name === 'back') router.go(-1)
      else if (props.to?.function) props.to['function']()
      else if (props.to) router.push(props.to)
    }

    return {
      goTo,
      appLoading,
      omit,
    }
  },
}
</script>

<style lang="scss">
.red {
  @apply bg-red-400 text-white active:bg-red-200;
}
.primary {
  @apply text-white bg-primary active:bg-primary-disabled;
}
.primary:disabled {
  @apply text-white bg-primary-disabled cursor-default;
}
.black {
  @apply bg-black text-white active:bg-gray-200;
}
.black:disabled {
  @apply text-gray-200 bg-gray-400 border-gray-600 cursor-default;
}
.green {
  @apply bg-green-500 text-white active:bg-green-200;
}
.gray {
  @apply bg-gray-200 active:bg-gray-100;
}
.secondary {
  @apply bg-white text-black border border-black active:bg-gray-100 rounded;
}
.secondary:disabled {
  @apply text-gray-400 bg-white border-gray-400 cursor-default;
}
.transparent {
  @apply bg-transparent text-black border-2 border-black leading-none active:bg-gray-100;
}
.transparent:disabled {
  @apply text-gray-400 bg-transparent border-gray-400 cursor-default;
}
.round {
  @apply rounded-full sm:min-w-0 w-8 h-8;
}
.fullyRounded {
  @apply rounded-full;
}
.outlined {
  @apply border-2 border-black;
}
.flat,
.flat:disabled {
  @apply bg-transparent text-black px-0;
}
.white {
  @apply bg-white shadow-lg text-black;
  &:active {
    @apply bg-gray-200;
  }
  &:disabled {
    @apply bg-white text-gray-500;
  }
}
button:disabled {
  @apply bg-gray-600 text-white cursor-default;
}
.transparent {
  @apply opacity-75;
}
.skinny {
  @apply px-4 py-1;
}
</style>
