import differenceInDays from 'date-fns/differenceInDays'
import en from '@/locales/en.json'
import { AlgoliaBooking } from '@/use/use-algoliasearch'
import { Booking, Checkin } from '@/types/api/types'

const { booking: bookingStatuses, checkin: checkinStatuses } = en.general.statuses

type DateFieldsAddedDuringProcessing = {
  checkinDate?: string
  checkoutDate?: string
  checkInDate?: string
  checkOutDate?: string
  checkedInAt?: string
  checkedOutAt?: string
  stayStartsAt?: string
  stayEndsAt?: string
  earlyCheckinAt?: string
  lateCheckoutAt?: string
  bookedAt?: string
}

type AllFieldsAddedDuringProcessing = {
  earlyCheckinAt?: string
  numNights: number
  preCheckedIn: boolean
  statusText: string
  checkins: Checkin[]
  status: string
} & DateFieldsAddedDuringProcessing

export type ProcessedBooking = AllFieldsAddedDuringProcessing & Partial<AlgoliaBooking> & Partial<Booking>

type BookingV1ResponseApprox = Booking & {
  tags?: string[]
  stayStartsAt?: string
  status: string
}

export const processBooking = (booking: AlgoliaBooking | BookingV1ResponseApprox): ProcessedBooking => {
  const isAlgoliaBooking = (booking: AlgoliaBooking | BookingV1ResponseApprox): booking is AlgoliaBooking =>
    'checkInDate' in booking

  const dateFields: DateFieldsAddedDuringProcessing = {
    checkinDate: booking['checkinDate'] && new Date(booking['checkinDate']),
    checkoutDate: booking['checkoutDate'] && new Date(booking['checkoutDate']),
    checkInDate: booking['checkInDate'] && new Date(booking['checkInDate']),
    checkOutDate: booking['checkOutDate'] && new Date(booking['checkOutDate']),
    checkedInAt: booking['checkedInAt'] && new Date(booking['checkedInAt']),
    checkedOutAt: booking['checkedOutAt'] && new Date(booking['checkedOutAt']),
    stayStartsAt: booking['stayStartsAt'] && new Date(booking['stayStartsAt']).toLocaleTimeString('en-US', {
      timeStyle: 'short',
    }),
    stayEndsAt: booking['stayEndsAt'] && new Date(booking['stayEndsAt']),
    earlyCheckinAt: (booking['earlyCheckinAt'] && new Date(booking['earlyCheckinAt']).toLocaleTimeString('en-US', {
      timeStyle: 'short',
    })) || '',
    lateCheckoutAt: booking['lateCheckoutAt'] && new Date(booking['lateCheckoutAt']).toLocaleTimeString('en-US', {
      timeStyle: 'short',
    }),
    bookedAt: booking['bookedAt'] && new Date(booking['bookedAt']),
  }

  const getStatusText = (booking: AlgoliaBooking | BookingV1ResponseApprox) => {
    // RG has special status rules, Ops don't want to see SLRG bookings as
    // "in stay" as soon as they've been approved, since they get approved
    // in advance of arrival and it's confusing that a booking can show as "in stay"
    // before they arrive.

    if (isAlgoliaBooking(booking)) {
      const isOnlineCheckin = ['SLRG'].includes(booking.propertyAcronym || '')
      const isBookingPastStartTime = booking.stayStartsAt
        ? Date.parse(booking.stayStartsAt) < Date.now()
        : Date.parse(booking.checkInDate || '') < Date.now()
      if (isOnlineCheckin) {
        if (booking.status === 'cancelled') return bookingStatuses['cancelled']
        if (!isBookingPastStartTime) return bookingStatuses['arrival']
      }

      return bookingStatuses[booking.status || '']
    } else {
      const isOnlineCheckin = ['Section L Residence Ginza'].includes(booking.propertyName || '')
      const isBookingPastStartTime = booking.stayStartsAt
        ? Date.parse(booking.stayStartsAt) < Date.now()
        : Date.parse(booking.checkinDate || '') < Date.now()
      if (isOnlineCheckin) {
        if (booking.status === 'cancelled') return bookingStatuses['cancelled']
        if (!isBookingPastStartTime) return bookingStatuses['arrival']
      }

      return bookingStatuses[booking.status || '']
    }
  }

  let processBookingResponse: ProcessedBooking = {
    ...booking,
    ...dateFields,
    ...(isAlgoliaBooking(booking) ? {
      numNights: differenceInDays(new Date(booking.checkOutDate || NaN), new Date(booking.checkInDate || NaN))
    } : {
      numNights: differenceInDays(new Date(booking.checkoutDate), new Date(booking.checkinDate)),
    }),
    preCheckedIn: booking.tags?.includes('pre_checked_in') || false,
    status: booking.status || '',
    statusText: getStatusText(booking),
    checkins: booking.checkins?.map(ci => ({
      ...ci,
      statusText: checkinStatuses[ci.status],
      completedAt: ci.completedAt && new Date(ci.completedAt),
    })) || [],
    minors: booking.minors?.map(m => m) || []
  }


  return processBookingResponse
}
