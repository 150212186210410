<template>
  <div
    v-if="currentUser"
    class="
      relative
      flex
      items-center
      justify-center
      ml-auto
      mr-10
      cursor-pointer
    "
  >
    <drop-down-menu
      ref="dropdownMenu"
      :actions="actions"
      class="float-right"
      offset
    >
      <avatar-img
        @click.stop="dropdownMenu.toggle()"
        :profile="currentUser.profile"
        class="w-12 h-12 rounded-full overflow-hidden"
        dark
      />
    </drop-down-menu>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import AvatarImg from '@/components/AvatarImg.vue'
import DropDownMenu from '@/components/DropDownMenu.vue'

export default {
  components: { AvatarImg, DropDownMenu },

  setup() {
    const router = useRouter()
    const store = useStore()
    const currentUser = computed(() => store.getters['auth/currentUser'])
    const dropdownMenu = ref(null)

    // Dropdown actions
    const actions = [
      {
        function: () => {},
        label: 'Personal settings',
        class: 'text-[#9CA3AF]',
      },
      {
        function: () => {
          router.push({
            name: 'PersonalSettingsProfile',
          })
        },
        label: 'Profile',
      },
      {
        function: () => {
          router.push({
            name: 'PersonalSettingsAccount',
          })
        },
        label: 'Account',
      },
      {
        function: () => {
          router.push({
            name: 'Logout',
          })
        },
        label: 'Log out',
        class: 'text-error',
      },
    ]

    return {
      actions,
      currentUser,
      dropdownMenu,
    }
  },
}
</script>
