<template>
  <base-label
    :class="[
      labelClass,
      { 'flex whitespace-nowrap items-center justify-between gap-3': inline },
    ]"
    :for="name"
  >
    {{ label }}
    <input
      :type="type"
      :name="name"
      :value="modelValue"
      @input="onInput($event.target.value)"
      v-bind="$attrs"
      :autocomplete="autocomplete ? 'on' : 'off'"
      class="
        block
        p-3
        w-full
        h-12
        disabled:text-gray-600
        bg-white
        border
        rounded
      "
      :class="[
        inputClass,
        {
          'mt-2': label && !inline,
        },
      ]"
    />
    <slot name="icon"></slot>
  </base-label>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    autocomplete: Boolean,
    debounce: {
      type: Number,
      default: 0,
    },
    modelValue: [String, Number],
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    inline: Boolean,
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator(value) {
        return [
          'date',
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'time',
          'url',
        ].includes(value)
      },
    },
  },

  computed: {
    name() {
      return this.label?.toLowerCase()
    },
  },

  methods: {
    // when debounce is 0, it will update the model without delay
    onInput(input) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit(
          'update:modelValue',
          this.type === 'number' ? parseInt(input) : input
        )
      }, this.debounce)
    },
  },
}
</script>
