<template>
  <div class="flex items-center justify-between w-full h-20 bg-white shadow">
    <img
      src="@/assets/logo.png"
      alt="Section L Logo"
      class="mx-10 w-10 h-10 cursor-pointer"
      @click="$router.push({ name: 'Home' })"
    />
    <Nav class="flex-grow h-full" />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  components: { Nav },
}
</script>
