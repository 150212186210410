import StaffsRepository from '@/api/repositories/staffs.js'

export const actions = {
  async getStaffs() {
    const response = await StaffsRepository.getStaffs()
    return response.data
  },
  async deleteStaff(_, { staffId }) {
    const response = await StaffsRepository.deleteStaff({ staffId })
    return response
  },
  async createStaff(_, { staff }) {
    const response = await StaffsRepository.createStaff({ staff })
    return response
  },
  async updateStaff(_, { staff }) {
    const response = await StaffsRepository.updateStaff({ staff })
    return response
  },
  async updateStaffProfile(_, { staffId, profile }) {
    const response = await StaffsRepository.updateStaffProfile({
      staffId,
      profile,
    })
    return response
  },
}
