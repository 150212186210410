export const state = {
  data: {},
}

export const getters = {
  data: state => state.data,
}

export const mutations = {
  CACHE_DATA(state, { module, scope, data }) {
    state.data[module] ||= {}
    state.data[module][scope] = data
  },
}

export const actions = {
  async cacheData({ commit }, { module, scope = 'default', data } = {}) {
    // The data can be retrieved from the cache by calling
    // store.getters['tables/data'][module][scope]
    commit('CACHE_DATA', { module, scope, data })
  },
}
