import Repository from './repository'

export default {
  getNotes({ bookingCode = null, guestId = null, search = null } = {}) {
    const path = bookingCode
      ? `/v1/admin/bookings/${bookingCode}/notes`
      : `/v1/admin/guests/${guestId}/notes`
    return Repository.get(path, { params: { search } })
  },
  createNote({ note, bookingCode = null, guestId = null } = {}) {
    return Repository.post(`/v1/admin/notes`, {
      note,
      bookingCode,
      guestId,
    })
  },
  updateNote({ note }) {
    return Repository.patch(`/v1/admin/notes/${note.id}`, { note })
  },
  deleteNote({ noteId }) {
    return Repository.delete(`/v1/admin/notes/${noteId}`)
  },
}
