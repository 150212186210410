import NotesRepository from '@/api/repositories/notes.js'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchNotes(_, { booking = null, guest = null, search = null } = {}) {
    const params = { search }
    if (booking) params.bookingCode = booking.bookingNo
    if (guest) params.guestId = guest.id
    const res = await NotesRepository.getNotes(params)
    if (res.status === 200) return res.data
  },
  async createNote(_, { note, bookingCode = null, guestId = null } = {}) {
    const res = await NotesRepository.createNote({ note, bookingCode, guestId })
    if (res.status === 200) return res.data
  },
  async updateNote(_, { note }) {
    const res = await NotesRepository.updateNote({ note })
    if (res.status === 200) return res.data
  },
  async deleteNote(_, { noteId }) {
    const res = await NotesRepository.deleteNote({ noteId })
    if (res.status === 200) return res
  },
}
