import PropertiesRepository from '@/api/repositories/properties.js'
import getSavedState from '@/utils/get-saved-state'
import saveState from '@/utils/save-state'

export const state = {
  property: getSavedState('property') ?? null,
  properties: getSavedState('properties') ?? [],
}

export const getters = {
  property(state) {
    return state.property
  },
  properties(state) {
    return state.properties
  },
}

export const mutations = {
  SET_PROPERTY(state, { property, save = false } = {}) {
    state.property = property
    if (save) saveState('property', property)
  },

  SET_PROPERTIES(state, { properties, save = false } = {}) {
    state.properties = properties
    if (save) saveState('properties', properties)
  },

  RESET_PROPERTY(state) {
    state.property = null
    saveState('property', null)
  },
}

export const actions = {
  async fetchProperties({ commit }) {
    const response = await PropertiesRepository.getProperties()
    commit('SET_PROPERTIES', { properties: response.data, save: true })
    return response.data
  },
  async fetchPropertiesForMinpaku({ commit }) {
    const response = await PropertiesRepository.getPropertiesForMinpaku()
    commit('SET_PROPERTIES', { properties: response.data, save: true })
    return response.data
  },
  async setProperty(
    { commit, dispatch, state },
    { id, includeMinpakuReports = false }
  ) {
    // When the property is found from state, no need to fetch
    let property = state.properties.find(p => `${p.id}` === `${id}`)

    if (!property || includeMinpakuReports) {
      const properties = await dispatch(
        includeMinpakuReports ? 'fetchPropertiesForMinpaku' : 'fetchProperties'
      )
      property = properties.find(p => `${p.id}` === `${id}`)
    }

    commit('SET_PROPERTY', { property, save: true })
    return property
  },
  async fetchProperty({ commit }, { passCode } = {}) {
    const response = await PropertiesRepository.getProperty({ passCode })
    const property = response.data
    commit('SET_PROPERTY', { property })
    return property
  },
  async updatePropertyRoomUnits({ dispatch }, { propertyId, roomUnits }) {
    const response = await PropertiesRepository.postPropertyRoomUnits({
      propertyId,
      roomUnits,
    })
    if (response.status === 204) {
      await dispatch('fetchProperties')
    }
    return response
  },
  async updatePropertyRoomUnit({ dispatch }, payload) {
    const response = await PropertiesRepository.patchPropertyRoomUnit(payload)

    if (response.status === 204) {
      await dispatch('setProperty', { id: payload.propertyId })
    }
    return response
  },
  async downloadMinpakuReport(_, payload) {
    const response =
      await PropertiesRepository.downloadPropertyRoomUnitMinpakuReport(payload)
    return response
  },
  async downloadMinpakuReports({ dispatch }, payload) {
    try {
      const response = await PropertiesRepository.getPropertyMinpakuReports(
        payload
      )
      return response
    } catch ({ error }) {
      await dispatch(
        'setAlert',
        {
          messages: [error],
        },
        { root: true }
      )
    }
  },
  async generateLatestMinpakuReports({ dispatch }, payload) {
    try {
      const response = await PropertiesRepository.postPropertyMinpakuReports(
        payload
      )
      if (response.status === 200) {
        await dispatch(
          'setAlert',
          {
            messages: [response.data?.message],
          },
          { root: true }
        )
        await dispatch('fetchPropertiesForMinpaku')
      }
    } catch ({ error }) {
      await dispatch(
        'setAlert',
        {
          messages: [error],
        },
        { root: true }
      )
    }
  },
  resetProperty({ commit }) {
    commit('RESET_PROPERTY')
  },
}
