import Repository from './repository'

export default {
  getBookings({ cancelToken, ...params } = {}) {
    return Repository.get(`/v1/admin/bookings`, { cancelToken, params })
  },
  getBooking({ bookingNo }) {
    return Repository.get(`/v1/admin/bookings/${bookingNo}`)
  },
  resetMockBookings() {
    return Repository.get('/v1/admin/bookings/reset_mocks')
  },
  patchBooking({ booking }) {
    return Repository.patch(`/v1/admin/bookings/${booking.bookingNo}`, {
      booking,
    })
  },
  connectBookings({ bookingNo, connectingBookingNo }) {
    return Repository.post(`/v1/admin/bookings/${bookingNo}/extensions`, {
      extensionBookingCode: connectingBookingNo,
    })
  },
  syncConnectingBookings({ bookingNo, previousBookingNo }) {
    return Repository.post(`/v1/admin/bookings/${bookingNo}/sync_extension`, {
      previousBookingCode: previousBookingNo,
    })
  },
  cancelConnectingBookings({ bookingNo, connectingBookingNo }) {
    return Repository.delete(
      `/v1/admin/bookings/${bookingNo}/extensions/${connectingBookingNo}`
    )
  },
  patchBookingRoomCode({ bookingNo, roomCode }) {
    return Repository.patch(`/v1/admin/bookings/${bookingNo}/room_code`, {
      roomCode,
    })
  },
  changePrimaryGuest({ bookingNo, newPrimaryCheckinId }) {
    return Repository.patch(
      `/v2/bookings/${bookingNo}/primary_checkin/${newPrimaryCheckinId}`
    )
  },
  undoCheckout({ bookingNo }) {
    return Repository.delete(`/v2/bookings/${bookingNo}/checkout/${bookingNo}`)
  },
  manualNoShow({ bookingNo }) {
    return Repository.post(`/v2/bookings/${bookingNo}/manual_no_show`)
  },
  undoManualNoShow({ bookingNo }) {
    return Repository.delete(
      `/v2/bookings/${bookingNo}/manual_no_show/${bookingNo}`
    )
  },
}
