import store from '@/store'
import { RouterView } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: { template: '<div></div>' },
    redirect: { name: 'Bookings' },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/pages/Login.vue'),
    meta: {
      title: 'Logout',
      authNotRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) await store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          route => !route.meta?.authNotRequired
        )

        next(authRequiredOnPreviousRoute ? { name: 'Login' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      title: 'Login',
      authNotRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/booking',
    redirect: '/bookings',
    children: [
      {
        path: ':bookingNo',
        redirect: to => `/bookings/${to.params.bookingNo}`,
      },
    ],
  },
  {
    path: '/bookings',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Bookings',
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          title: 'Bookings',
        },
      },
      {
        path: 'today',
        alias: '',
        name: "Today's Activity",
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          scope: 'today',
        },
      },
      {
        path: 'tomorrow',
        alias: '',
        name: "Tomorrow's Activity",
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          scope: 'tomorrow',
        },
      },
      {
        path: 'instay',
        alias: '',
        name: 'In-Stay Bookings',
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          scope: 'instay',
        },
      },
      {
        path: 'future',
        alias: '',
        name: 'Upcoming Bookings',
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          scope: 'future',
        },
      },
      {
        path: 'past',
        alias: '',
        name: 'Past Bookings',
        component: () => import('@/pages/Bookings.vue'),
        meta: {
          scope: 'past',
        },
      },
      {
        path: ':bookingNo',
        component: () => import('@/pages/Booking.vue'),
        props: route => ({ bookingNo: parseInt(route.params.bookingNo) }),
        meta: {
          title: 'Booking',
        },
        children: [
          {
            path: '',
            redirect: route => `/bookings/${route.params.bookingNo}/details`,
          },
          {
            path: 'details',
            alias: '',
            name: 'Booking',
            component: () => import('@/pages/bookings/Details.vue'),
            meta: {
              scope: 'details',
            },
          },
          {
            path: 'notes',
            name: 'BookingNotes',
            component: () => import('@/pages/Notes.vue'),
            meta: {
              scope: 'notes',
            },
          },
          {
            path: 'auto_messages',
            name: 'Auto Messages',
            component: () => import('@/pages/bookings/AutoMessages.vue'),
            meta: {
              scope: 'auto_messages',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/personal_settings',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'PersonalSettings',
        component: () => import('@/pages/PersonalSettings.vue'),
        meta: {
          title: 'Personal Settings',
          fullWidth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'PersonalSettingsProfile',
            component: () => import('@/pages/personalSettings/Profile.vue'),
            meta: {
              scope: 'profile',
            },
          },
          {
            path: 'account',
            name: 'PersonalSettingsAccount',
            component: () => import('@/pages/personalSettings/Account.vue'),
            meta: {
              scope: 'account',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/guests',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Guestbook',
        component: () => import('@/pages/Guestbook.vue'),
        meta: {
          title: 'Guestbook',
        },
      },
      {
        path: ':guestId',
        component: () => import('@/pages/Guest.vue'),
        props: route => ({ guestId: parseInt(route.params.guestId) }),
        meta: {
          title: 'Guest',
        },
        children: [
          {
            path: 'info',
            name: 'GuestInfo',
            component: () => import('@/pages/guests/Info.vue'),
            meta: {
              scope: 'info',
            },
          },
          {
            path: 'profile',
            alias: '',
            name: 'Guest',
            component: () => import('@/pages/guests/Profile.vue'),
            meta: {
              scope: 'profile',
            },
          },
          {
            path: 'history',
            name: 'GuestBookingHistory',
            component: () => import('@/pages/guests/History.vue'),
            meta: {
              scope: 'history',
            },
          },
          {
            path: 'notes',
            name: 'GuestNotes',
            component: () => import('@/pages/Notes.vue'),
            meta: {
              scope: 'notes',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/chats/:chatId?',
    alias: '/messages',
    name: 'Messages',
    props: route => ({ chatId: parseInt(route.params.chatId) }),
    component: () => import('@/pages/Messages.vue'),
    meta: {
      title: 'Messages',
      fullWidth: true,
    },
  },
  {
    path: '/events',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Events',
        component: () => import('@/pages/Events.vue'),
        meta: {
          title: 'Events',
        },
      },
      {
        path: ':eventId',
        name: 'Event',
        component: () => import('@/pages/Event.vue'),
        props: route => ({ eventId: parseInt(route.params.eventId) }),
        meta: {
          title: 'Event',
        },
      },
    ],
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('@/pages/Members.vue'),
    meta: {
      title: 'Members',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/pages/Reports.vue'),
    meta: {
      title: 'Reports',
    },
  },
]

export default routes;

declare module 'vue-router' {
  interface RouteMeta {
    authNotRequired?: boolean
    title?: string
    scope?: string
    fullWidth?: boolean
  }
}
