<template>
  <BaseLink
    v-for="route in routes"
    :key="route.name"
    :to="processRoute(route)"
    active-class="text-black border-b-[3px] border-t-[3px] border-b-primary border-t-transparent cursor-default font-bold"
    class="px-6 h-full text-gray-600 no-underline leading-none"
    :class="route.classes"
  >
    <li class="flex items-center justify-center w-full h-full">
      <BaseIcon
        v-if="route?.icon"
        :name="route?.icon?.name"
        :source="route?.icon?.source"
        class="mr-3"
      />
      {{ route.title }}
    </li>
  </BaseLink>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getRouteAttr(route, attr) {
      return typeof route[attr] === 'function' ? route[attr]() : route[attr]
    },

    processRoute(route) {
      Object.keys(route).forEach(
        attr => (route[attr] = this.getRouteAttr(route, attr))
      )
      return route
    },
  },
}
</script>
