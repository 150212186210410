<template>
  <cloudinary-img
    v-if="profile && profile.public && profile.photoKey"
    :image-key="profile.photoKey"
    :params="{ options: 'w_256/g_face' }"
    alt="profile avatar"
    class="object-cover"
    v-bind="$attrs"
  />
  <div
    v-else
    class="grid text-3xl font-semibold text-center border-2 place-items-center"
    :class="{
      '!text-sm': initials?.length > 1,
      'bg-white border-gray-900': !dark,
      'bg-[#1F2937] border-[#1F2937] text-white': dark,
    }"
    v-bind="$attrs"
  >
    {{ initials }}
  </div>
</template>

<script>
import CloudinaryImg from '@/components/CloudinaryImg.vue'
import { computed } from 'vue'

export default {
  components: { CloudinaryImg },

  props: {
    profile: Object,
    dark: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const initials = computed(() => {
      return props.profile?.name
        ?.split(' ')
        .slice(0, 2) // Only take the first two words
        .map(name => name[0])
        .join('')
        .toUpperCase()
    })

    return { initials }
  },
}
</script>
