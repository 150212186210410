import Repository from './repository'

export default {
  getGuests({ cancelToken, ...params } = {}) {
    return Repository.get('/v1/admin/guests', { cancelToken, params })
  },

  getGuest({ id = null, email = null } = {}) {
    return Repository.get(
      id ? `/v1/admin/guests/${id}` : `/v1/admin/guests/find?email=${email}`
    )
  },

  createGuest: ({ guest }) => {
    return Repository.post(`/v1/admin/guests`, { guest })
  },

  patchGuest: ({ guest }) => {
    return Repository.patch(`/v1/admin/guests/${guest.id}`, { guest })
  },
}
