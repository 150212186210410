import algoliasearch, { SearchIndex } from 'algoliasearch/lite'
import { config } from '@/constants'

// This must be manually kept up to date with what kind of shape we expect every booking to have in Algolia
export type AlgoliaBooking = {
  arrivalTime?: string
  bookingNo?: number
  cancellableConnection?: boolean
  checkInDate?: string
  checkInDateUnix?: number
  checkOutDate?: string
  checkOutDateUnix?: number
  checkedInAt?: string
  checkedOutAt?: string
  checkins?: {
    allDataCollected?: boolean
    birthdayWithinStay?: boolean
    guest?: {
      dateOfBirth?: string
      email?: string
      fullName?: string
      hasLoggedInAtLeastOnce?: boolean
      id?: number
      idPhotoKey?: string
      returning?: boolean
    }
    id?: number
    photoKey?: string
    primary?: boolean
    status?: string
  }[]
  displayCheckinTime?: string
  displayCheckoutTime?: string
  fullName?: string
  groupBookings?: {
    b24_id?: number
    checkin_date?: string
    checkout_date?: string
    room_number?: string
    total_pax?: number
  }[]
  id?: number
  manual_no_show?: boolean
  masterBooking?: {
    bookingNo?: number
  }
  minors?: {
    allDataCollected?: boolean
    dateOfBirth?: string
    firstName?: string
    id?: number
    idPhotoKey?: string
    lastName?: string
    photoKey?: string
  }[]
  nextBooking?: {
    bookingNo?: number
  }
  notesCount?: number
  numAdult?: number
  numChild?: number
  objectID?: string
  overdue?: boolean
  previousBooking?: {
    bookingNo?: number
  }
  property?: string
  propertyAcronym?: string
  referer?: string
  returning?: boolean
  roomCode?: string
  roomCodes?: string[]
  roomNumber?: string
  roomNumberPadded?: string
  roomType?: string
  status?: string
  stayEndsAt?: string
  stayStartsAt?: string
  syncableBookingNos?: number[]
  tags?: string[]
}

const algoliaClient = algoliasearch(config.algolia.appId, config.algolia.key)

const bookingsIndex = algoliaClient.initIndex(config.algolia.bookingsIndex)
const bookingsIndexCheckoutDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckoutDesc
)
const bookingsIndexCheckinDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckinDesc
)
const bookingsIndexCheckoutAsc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckoutAsc
)
const bookingsIndexRoomNumberDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexRoomNumberDesc
)

const indices = {
  bookings: bookingsIndex,
  today: bookingsIndex,
  tomorrow: bookingsIndex,
  future: bookingsIndex,
  past: bookingsIndexCheckoutDesc,
  checkInDesc: bookingsIndexCheckinDesc,
  checkOutAsc: bookingsIndexCheckoutAsc,
  roomNumberDesc: bookingsIndexRoomNumberDesc,
}

const getIndex = (scope, sortSelection = 'checkInDesc', searchQuery): SearchIndex => {
  if (searchQuery) return bookingsIndexCheckoutDesc // when there is a typed search query, the results are sorted by latest check out date first

  if (scope === 'instay') return indices[sortSelection] // when looking at the instay tab, the result sorting depends on the selected sorting option

  return indices[scope] || bookingsIndex // for all other scopes choose the relevant index, or if not known, the default 'bookings' index
}

export const fastSearch = async (
  scope,
  sortSelection,
  searchQuery,
  options
) => {
  const indexToSearch = getIndex(scope, sortSelection, searchQuery)
  if (!indexToSearch) return null

  return await indexToSearch.search<AlgoliaBooking>(searchQuery, options)
}
