import Repository from './repository'

export default {
  // From Booking page, create, update, and delete minor information
  postMinor({ bookingNo, minor }) {
    return Repository.post(`/v1/admin/bookings/${bookingNo}/minors`, { minor })
  },
  patchMinor({ bookingNo, minor }) {
    return Repository.patch(
      `/v1/admin/bookings/${bookingNo}/minors/${minor.id}`,
      {
        minor,
      }
    )
  },
  deleteMinor({ bookingNo, minor }) {
    return Repository.delete(
      `/v1/admin/bookings/${bookingNo}/minors/${minor.id}`
    )
  },
  moveMinor({ bookingNo, minor, newBookingNo }) {
    return Repository.post(
      `/v1/admin/bookings/${bookingNo}/minors/${minor.id}/move`,
      { newBookingNo }
    )
  },
}
