import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
import NProgress from 'nprogress/nprogress'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }

  // Check if auth is not required on this route
  const authNotRequired = routeTo.meta.authNotRequired
  // If auth isn't required for the route, just continue.
  if (authNotRequired) return true

  // If auth is required
  return store.getters['auth/loggedIn']
    ? true
    : { name: 'Login', query: { redirectFrom: routeTo.fullPath } }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  // For each matched route...
  routeTo.matched.forEach(async route => {
    try {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    } catch (error) {
      // If a `beforeResolve` hook chose to redirect, just return.
      return
    }
  })

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach(() => {
  // router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
