import EventsRepository from '@/api/repositories/events.js'

export const state = {
  events: [],
  event: null
}

export const getters = {
  events(state) {
    // // This currently crashes the app
    // return state.events.sort(
    //   (a, b) => new Date(a.startTime) - new Date(b.startTime)
    // )
    return state.events
  },
  event(state) {
    return state.event
  }
}

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events
  },
  SET_EVENT(state, event) {
    state.event = event
  },
  RESET_EVENT(state) {
    state.event = null
  },
  DELETE_COMMENT(state, commentId) {
    const idxToDelete = state.event.comments?.findIndex(c => c.id === commentId)
    state.event.comments.splice(idxToDelete, 1)
  },
  DELETE_EVENT(state, eventId) {
    state.events = state.events.filter(event => event.id != eventId)
  },
  CACHE_EVENT(state, { event }) {
    const idxToUpdate = state.events.findIndex(e => e.id === event.id)

    if (idxToUpdate >= 0) state.events[idxToUpdate] = event
    else state.events.push(event)
  },
}

export const actions = {
  async fetchEvent({ commit }, eventId) {
    commit('RESET_EVENT')
    const res = await EventsRepository.getEvent(eventId)
    commit('SET_EVENT', res.data)
    return res.data
  },
  async deleteComment({ commit }, commentId) {
    const res = await EventsRepository.deleteComment(commentId)
    if (res.status === 200) commit('DELETE_COMMENT', commentId)
    return res.data
  },
  async fetchEvents({ commit }, { scope, eventType, search }) {
    commit('SET_EVENTS', [])
    const res = await EventsRepository.getEvents({
      scope,
      eventType,
      search,
    })
    commit('SET_EVENTS', res.data.events)
    return res.data
  },
  async createEvent({ commit }, { event }) {
    const res = await EventsRepository.createEvent({ event })
    if (res.status === 200) commit('CACHE_EVENT', { event: res.data })
    return res.data
  },
  async deleteEvent({ commit }, { eventId }) {
    const res = await EventsRepository.deleteEvent({ eventId })
    if (res.status === 200) commit('DELETE_EVENT', eventId)
    return res
  },
  async duplicateEvent({ commit }, { eventId }) {
    const res = await EventsRepository.duplicateEvent({ eventId })
    if (res.status === 200) {
      commit('CACHE_EVENT', {
        event: {
          ...res.data,
        },
      })
    }
    return res.data
  },
  async updateEvent({ commit }, { eventId, event }) {
    const res = await EventsRepository.updateEvent({ eventId, event })
    if (res.status === 200) {
      commit('CACHE_EVENT', {
        event: {
          ...res.data,
        },
      })
      commit('SET_EVENT', res.data)
    }
    return res.data
  },
}
